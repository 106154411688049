@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base';

.container {
  padding: 0 20px;
  max-width: 1720px;
  margin: 25px auto;
  font-weight: $bold;

  @include media($min-md) {
    padding: 0 40px;
  }

  @include media($min-xl) {
    padding: 0 60px;
  }
}

.crumbs {
  width: 100%;
  font-size: rem(14);
}

.crumb {
  margin-right: 7px;
  color: $medium-gray;
  font-weight: $bold;
  display: inline-block;
}

.crumb-link {
  display: inline-flex;
  color: $blue-700;
  font-weight: $bold;
  font-size: rem(14);
  line-height: 1.45;

  &:hover {
    color: $blue-500;
  }
}

.crumb-arrow {
  margin-right: 10px;
  font-size: rem(20);
}
