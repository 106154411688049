@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_cms';

.loading {
  margin: 100px 0;
}

:global {
  .contentpage {
    margin: 0 auto;
    max-width: 960px;

    h1,
    h2,
    h3 {
      img {
        display: inline-block;
      }
    }

    h1 {
      margin: 1em 0 0.5em;
      font-size: 1.9rem;
      font-weight: 300;
      letter-spacing: 0.01rem;
      white-space: normal;

      @include media($min-sm) {
        white-space: nowrap;
      }
    }

    h2 {
      margin: 2em 0 0.2em;
      color: $dt-red;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      white-space: normal;

      @include media($min-sm) {
        white-space: nowrap;
      }
    }

    h3 {
      margin: 0 0 0.6em;
      padding-top: 20px;
      padding-bottom: 6px;
      border-bottom: 1px solid #e3e3e6;
      font-size: 0.9rem;
      text-transform: uppercase;
      white-space: normal;

      @include media($min-sm) {
        white-space: nowrap;
      }
    }

    p {
      margin: 0 0 18px;
      color: #333;
      font-size: 0.93rem;
      line-height: 1.6;
    }

    ul {
      font-size: rem(12);
      a {
        font-size: rem(12);
      }
    }

    .dt-subhead {
      margin-bottom: 1.6em;
      padding-bottom: 1.6em;
      border-bottom: 1px solid #e3e3e6;
      font-size: 1.1rem;
    }

    .note {
      font-size: 0.9rem;
      padding: 1.6em 0.4em;
      color: #222;
    }

    .note strong {
      display: block;
      font-size: 1rem;
    }

    .rebates-list {
      h2 {
        margin-top: 0;
        color: black;
        a {
          color: black;
        }
      }

      .banner-row {
        margin-bottom: 20px;
      }

      .simple-banner {
        width: 100%;
      }
    }

    .dt-list {
      display: flex;
      flex-direction: column;
      margin: 2em 0;
      color: #202020;
      font-size: 0.92rem;

      a {
        font-size: inherit;
      }

      li {
        position: relative;
        margin: 0;
        padding: 0.4em 2em 0.4em 4em;
        width: 84%;

        font-size: 0.92rem;
        font-weight: bold;

        strong {
          display: block;
          margin-bottom: 0.3em;
          color: #000;
          font-size: 0.93rem;
          font-weight: 500;
          letter-spacing: 0.01em;
          text-transform: uppercase;
        }
      }

      li:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 30px;
        height: 13px;
        width: 13px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23222' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E")
          no-repeat;
      }
    }

    ol.dt-list li {
      margin: 1em 0;
      padding-left: 2em;

      &:before {
        content: '';
      }
    }

    ol.dt-list li,
    .dt-list--tire-pressure li {
      font-weight: normal;
      width: 86%;
    }

    .dt-definitions {
      margin: 3em 0;

      dt {
        margin: 2em 0 0.4em;
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
      }

      dd,
      dd p {
        font-size: 0.9rem;
        color: #777;
      }
    }

    .rma-guidelines__list li {
      padding: 1em 2em 1em 4em;
      color: #777;
      font-weight: normal;

      strong {
        margin-bottom: 0.2em;
      }
    }

    .dt-callout {
      margin: 3em 0;
      padding: 1.4em 2em;
      background: #fafafe;
      font-size: 1rem;
      border: 1px solid #e3e3e6;
      border-radius: 4px;

      strong {
        margin-right: 0.3em;
      }
    }

    .cmsimage {
      margin: 3em 0;
    }

    .dt-nav__menu {
      li {
        padding: 0.6em 0 0.6em 0.3em;
        border-top: 1px #f5f5f5 solid;
        font-size: 0.82rem;
        font-weight: 500;
      }

      li:first-child {
        border-top: none;
      }

      li a {
        transition: all 0.3s ease;
      }

      li a:hover {
        text-decoration: underline;
      }
    }

    .contentpage-quicktips {
      &__block {
        width: 50%;
      }
      &__title {
        padding: 5px 0 10px;
        font-size: rem(18);
      }
      &__list {
        color: $medium-gray;
        list-style: disc inside;
        li {
          padding-bottom: 5px;
        }
      }
      &__text {
        color: $medium-gray;
        font-size: rem(14);
      }
    }

    .section4a-informational.section4a-informational {
      margin-right: 40px;
      width: 75%;
      float: left;
    }

    .section4b-informational.section4b-informational {
      width: 15%;
      min-width: 200px;
      float: left;
      @media ($min-sm) {
        float: right;
      }
    }

    &__p {
      padding: 10px 0;
      color: $medium-gray;
    }
    &__button {
      border-radius: 25px;
      border: 3px solid $dt-red;
      color: $dt-red;
      background-color: $white;
      text-align: center;
      display: block;
      margin-bottom: 40px;
      &:hover {
        background-color: $dt-red;
        color: $white;
      }
    }
    &__divider {
      border-color: $light-gray;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 20px 0;
      clear: both;
    }
    &__row {
      display: table;
    }
  }

  .contentblock {
    margin-top: 3em;
    border-top: 1px solid #e3e3e6;
  }

  .contentblock__actions {
    margin-top: 2em;
  }

  /* Tips & Guides Hub Page */

  .contentpage-hub__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .contentpage-topic__container h2 {
    margin-top: 0.5em;
    font-size: 0.94rem;
    font-weight: bold;
  }

  .contentpage .dt-nav__menu,
  .contentpage .dt-nav__list,
  .contentpage-topic__container .dt-list {
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding: 0.6em 1em 0.6em 0.3em;
      margin: 0;
      width: 100%;

      font-weight: normal;
      border-bottom: 1px solid #eee;
      white-space: nowrap;

      transition: all 0.3s ease;

      &:last-child {
        border-bottom: 0;
      }

      &:before {
        content: '';
        background: none;
      }

      &:after {
        content: '';
        height: 18px;
        width: 8px;
        position: absolute;
        right: 8px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='%23ed1c24' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' %3E%3C/path%3E%3C/svg%3E")
          no-repeat;
        opacity: 0;

        transition: all 0.25s ease;
      }

      &:hover {
        cursor: pointer;
      }

      &:hover:after {
        right: 3px;
        opacity: 1;
      }

      &:hover a,
      a:active,
      a:hover {
        color: $dt-red;
      }

      a,
      a:visited {
        padding: 0.7em 1em 0.7em 0.3em;
        color: #222;
        font-size: 0.8rem;
      }
    }
  }
}
